import React, { useState, useEffect } from "react"
import SearchModule from "../search2/searchModule"

import TopHeroComponent from "../herocomponents/topherocomponent"
import ContactForm from "../bodycomponents/contact-form"
import FormComponent from "../bodycomponents/form-component"
import Maximizing from "../bodycomponents/maximizing"
import GRAY_ARROW_BG from "../../images/SSLLC_Arrow_Movement.svg"
import RED_BG from "../../images/background-images/bg-red.svg"
import M_GRAY_BG from "../../images/background-images/bg-medium-gray.svg"
import { colors } from "../../utils/colors"
import { newArrivalsFaqItems, refurbishedFaqItems } from "../../utils/data/faq"
import FAQ from "../faq-components/faq"

import GCMS from "../../images/refurbished-equipment/SSLLC-Refurbished-Equipment-GCMS-600x600.jpg"
import Incubators from "../../images/refurbished-equipment/SSLLC-Equipment_New-Arrivals_Lab-Equipment.webp"
import LCMS from "../../images/refurbished-equipment/SSLLC-Refurbished-Equipment-LCMS-600x600.jpg"
import Centrifuges from "../../images/refurbished-equipment/SSLLC-Refurbished-Equipment-Centrifuges.jpg"
import BiosafetyCabinets from "../../images/refurbished-equipment/SSLLC-Refurbished-Equipment-Biosafety-Cabinets-600x600.jpg"
import HPLC_UPLC from "../../images/refurbished-equipment/SSLLC-Refurbished-Equipment-HPLC-and-UPLC-2-600x600.webp"
import Refrigerators_Freezers from "../../images/refurbished-equipment/SSLLC-Refurbished-Equipment-Fridge-and-Freezer.jpg"

const CatalogListing = (props) => {
  const [searchFilters, setSearchFilters] = useState({})
  const [categoryId, setCategoryId] = useState(null)
  const [categoryBlurb, setCategoryBlurb] = useState(null)
  const [NewArrivals, setNewArrivals] = useState(false)
  const [Refurbished, setRefurbished] = useState(false)

  useEffect(() => {
    import(`../../../static/categories.json`).then(
      ({ default: categoriesMap }) => {
        if (props.rootCategory === "new-arrivals") {
          setSearchFilters({ toggle: { isNewArrival: true } })
          setNewArrivals(true)
        } else if (props.rootCategory === "featured") {
          console.log("featured")
          setSearchFilters({ toggle: { isFeatured: true } })
        } else if (props.rootCategory === "refurbished") {
          setSearchFilters({ toggle: { isRefurbished: true } })
          setRefurbished(true)
        } else {
          var indexPath = ""
          let pathSegments = props.location.pathname
            .replace("/catalog/", "")
            .split("/")
          var pathSegmentLookups = []
          var categoryId = null
          pathSegments.forEach((segment) => {
            indexPath = indexPath + `/${segment}`
            pathSegmentLookups.push(categoriesMap[indexPath].name)
            categoryId = categoriesMap[indexPath].sfId
            //categoryPath = categoryPath + `${categoriesMap[indexPath]} `
          })
          setCategoryId(categoryId)
          var categoryPath = pathSegmentLookups.join(" > ")
          const filter = {
            hierarchicalMenu: { "categories.lvl0": categoryPath },
            page: 1,
          }
          // console.log("filter path", categoryPath)
          setSearchFilters(filter)
        }
      }
    )
  }, [props.location.pathname])

  useEffect(() => {
    if (categoryId)
      axios
        .get(
          `/categoryLookup?sfId=${categoryId}&version=${process.env.GATSBY_DBVERSION}`
        )
        .then((response) => {
          setCategoryBlurb(response.data)
        })
        .catch((error) => {
          console.error("cannot fetch catalog info")
        })
  }, [categoryId])

  // console.log("filter", searchFilters)

  return (
    <>
      {/* New Arrivals page */}
      {NewArrivals && (
        <>
          <TopHeroComponent
            backgroundImage={RED_BG}
            backgroundColor={colors.scarletRed}
            fontShadowColor={colors.red}
            shadowColor="shadow-red-bottom"
            pageTitle="New Arrivals"
            title="Shop the Latest Equipment Arrivals"
            body={
              <p className="text-balance pt-4">
                Our inventory is continually refreshed with the latest,
                premium-quality lab equipment for a wide range of scientific
                applications. Sought-after items sell quickly after being
                listed, so explore our newest arrivals now to secure the
                highest-quality models and maximize your lab’s value.
              </p>
            }
          />
          <div className="shadow-custom-out">
            <SearchModule
              showManufacturer={true}
              searchFilters={searchFilters}
              blurb={categoryBlurb}
              isNewArrivalRoot={true}
            />
          </div>
        </>
      )}

      {/* Refurbished page */}
      {Refurbished && (
        <>
          <TopHeroComponent
            backgroundImage={RED_BG}
            backgroundColor={colors.scarletRed}
            fontShadowColor={colors.red}
            shadowColor="shadow-red-bottom"
            pageTitle="Refurbished Equipment"
            title={
              <>
                Certified Surplus:
                <br />
                High-Quality Refurbished Equipment
              </>
            }
            body={
              <p className="text-balance pt-4">
                At Surplus Solutions, our Certified Surplus™ refurbished
                collection offers high-quality, reliable equipment to meet your
                process demands. With a vast inventory from top manufacturers
                and various certification options, you can upgrade with
                confidence. Explore our categories to find the right solution
                for your lab!
              </p>
            }
          />
          <div className="shadow-custom-out">
            <SearchModule
              showManufacturer={true}
              searchFilters={searchFilters}
              blurb={categoryBlurb}
              isNewArrivalRoot={true}
            />
          </div>
          {/* Form */}
          <div id="refurbished-equipment"></div>
          <div className="shadow-custom-out">
            <div className="container max-w-screen-xl py-14">
              <div>
                <div className="w-full flex flex-col-reverse md:flex-row gap-8">
                  <div className="w-full md:w-1/2">
                    <p>
                      At Surplus Solutions, our Certified Surplus™ collection
                      offers a range of certification options to give you the
                      assurance that the equipment you get—from a vast inventory
                      that includes top manufacturers—is up to the demands of
                      your process.
                    </p>
                    <p className="font-semibold py-2">
                      Choose from our categories and get solutions tailored to
                      your needs—upgrade today!
                    </p>
                    <div className="w-full flex flex-row">
                      <ul className="w-1/2 list-disc pl-5 text-wrap">
                        <li>Refrigerators</li>
                        <li>Freezers</li>
                        <li>Incubators</li>
                        <li>Centrifuges</li>
                        <li>Balances</li>
                        <li>Laminar Airflow Hoods</li>
                        <li>Stir Plate</li>
                        <li>Biosafety Cabinets</li>
                        <li>HPLC / UPLC</li>
                      </ul>
                      <ul className="w-1/2 list-disc pl-5 text-wrap">
                        <li>Gas Chromatography Mass Spec (GCMS)</li>
                        <li>Liquid Chromatography Mass Spec (LCMS)</li>
                        <li>
                          Inductively Coupled Plasma Atomic Emission
                          Spectroscopy
                        </li>
                        <li>Spectrophotometers</li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <h3 className="pl-4">Request refurbished equipment now!</h3>
                    <FormComponent formType="Refurbishment" />
                  </div>
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-7 gap-6 justify-items-center text-center pb-10">
                  {/* Item 1 */}
                  <div className="flex flex-col items-center">
                    <img
                      src={GCMS}
                      alt="GCMS"
                      className="w-40 h-40 object-contain"
                    />
                    <h5 className="mb-0">GCMS</h5>
                  </div>

                  {/* Item 2 */}
                  <div className="flex flex-col items-center">
                    <img
                      src={Incubators}
                      alt="Incubators"
                      className="w-40 h-40 object-contain"
                    />
                    <h5 className="mb-0">Incubators</h5>
                  </div>

                  {/* Item 3 */}
                  <div className="flex flex-col items-center">
                    <img
                      src={LCMS}
                      alt="LCMS"
                      className="w-40 h-40 object-contain"
                    />
                    <h5 className="mb-0">LCMS</h5>
                  </div>

                  {/* Item 4 */}
                  <div className="flex flex-col items-center">
                    <img
                      src={Centrifuges}
                      alt="Centrifuges"
                      className="w-40 h-40 object-contain"
                    />
                    <h5 className="mb-0">Centrifuges</h5>
                  </div>

                  {/* Item 5 */}
                  <div className="flex flex-col items-center">
                    <img
                      src={BiosafetyCabinets}
                      alt="Biosafety Cabinets"
                      className="w-40 h-40 object-contain"
                    />
                    <h5 className="mb-0">Biosafety Cabinets</h5>
                  </div>

                  {/* Item 6 */}
                  <div className="flex flex-col items-center">
                    <img
                      src={HPLC_UPLC}
                      alt="HPLC & UPLC"
                      className="w-40 h-40 object-contain"
                    />
                    <h5 className="mb-0">HPLC & UPLC</h5>
                  </div>

                  {/* Item 7 */}
                  <div className="flex flex-col items-center">
                    <img
                      src={Refrigerators_Freezers}
                      alt="Refrigerators & Freezers"
                      className="w-40 h-40 object-contain"
                    />
                    <h5 className="mb-0">Refrigerators & Freezers</h5>
                  </div>
                </div>
              </div>
              <hr className="border border-light-gray mb-5" />
              <div className="text-center">
                {/* Title Section */}
                <h2 className="text-red mb-4 pt-8">Pick the Perfect Level</h2>
                <p className="pb-8">
                  We offer a range of certification options to meet your
                  individual needs. Our rigorous refurbishment process ensures
                  the equipment meets or exceeds OEM performance standards. Each
                  item undergoes mechanical inspection and repair, followed by
                  thorough testing to ensure all systems are operating as
                  intended. We also offer testing options for cost-conscious
                  customers looking for the best balance of quality and
                  affordability. Explore our Certified Surplus collection to
                  find cost-effective solutions that don’t require compromises,
                  or connect with our team for guidance on the best options for
                  your specific operational needs.
                </p>

                {/* Certification Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                  {/* Tested Card */}
                  <div className="border border-gray rounded-[4px] shadow-md hover:shadow-lg hover:scale-[102%] transform transition duration-300 flex flex-col justify-between">
                    <div>
                      <div className="bg-red text-white p-4 rounded-t-[4px]">
                        <h3 className="mb-0">Certified</h3>
                      </div>
                      <div>
                        <ul>
                          <li className="px-4 pt-5 pb-1">30-Day Warranty</li>
                          <li className="px-4 pt-1 pb-5">
                            Tested to OEM Standards
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg-light-gray p-4 text-balance italic rounded-b-[4px]">
                      <p>
                        Manage costs while getting greater assurance from your
                        equipment.
                      </p>
                    </div>
                  </div>

                  {/* Refurbished Card */}
                  <div className="border border-gray rounded-[4px] shadow-md hover:shadow-lg hover:scale-[102%] transform transition duration-300 flex flex-col justify-between">
                    <div>
                      <div className="bg-red text-white p-4 rounded-t-[4px]">
                        <h3 className="mb-0">Refurbished</h3>
                      </div>
                      <div>
                        <ul>
                          <li className="px-4 pt-5 pb-1">90-Day Warranty</li>
                          <li className="px-4 py-1">Tested to OEM Standards</li>
                          <li className="px-4 pt-1 pb-5">
                            Inspected & Repaired with Detailed Reporting
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg-light-gray p-4 text-balance italic rounded-b-[4px]">
                      <p>
                        Great savings with reliable quality you can count on.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FAQ
            title="Refurbished FAQs"
            items={refurbishedFaqItems}
            backgroundColor={colors.lightGray}
            backgroundImage={M_GRAY_BG}
            shadowColor="shadow-mediumGray-top"
          />

          <Maximizing
            backgroundImage={GRAY_ARROW_BG}
            title="Need reliable refurbished equipment?"
            body={
              <p>
                Choose from our categories and get solutions tailored to your
                needs—upgrade today!
              </p>
            }
            to="#refurbished-equipment"
            buttonTitle="Reach Out Now"
            buttonColor={colors.royalBlue}
            hoverColor={colors.blue}
          />
        </>
      )}

      {/* Rest of the pages */}
      {!NewArrivals && !Refurbished && (
        <SearchModule
          showManufacturer={true}
          searchFilters={searchFilters}
          blurb={categoryBlurb}
        />
      )}

      {!Refurbished && (
        <>
          <FAQ
            title="FAQs"
            items={newArrivalsFaqItems}
            backgroundColor={colors.lightGray}
            backgroundImage={M_GRAY_BG}
            shadowColor="shadow-mediumGray-top"
          />

          <ContactForm
            title="Sign Up for Alerts on New Arrivals"
            body={
              <p>
                Never miss out! Subscribe now to receive email updates on our
                latest lab equipment arrivals, special offers, and more.
              </p>
            }
            backgroundColor={colors.containerGray}
            fontShadowColor={colors.red}
            shadow="shadow-red-bottom"
            formType="Marketing Sign Up"
          />
        </>
      )}
    </>
  )
}

export default CatalogListing
